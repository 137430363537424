<template>
  <div>
    <a-button
      type="primary"
      :style="{ marginBottom: '15px' }"
      @click="$router.push({ name: 'filters-new' }).catch(_err => {})"
    >
      Создать новый фильтр
    </a-button>

    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :row-key="record => record.id"
      :pagination="{ current: pageNumber, total: filtersTotal }"
      :data-source="filters"
      @change="handleTableChange"
    >
      <div
        slot="is_active"
        slot-scope="record"
        style="display: flex; justify-content: center"
      >
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          :title="
            `Вы уверены, что хотите ${
              record.is_active ? 'отключить' : 'включить'
            } значение?`
          "
          @confirm="updateFilter(record, 'is_active')"
        >
          <a-checkbox v-model="record.is_active" />
        </a-popconfirm>
      </div>

      <span
        slot="actions"
        slot-scope="record"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <a-button
          icon="edit"
          title="Редактировать"
          type="primary"
          @click="
            $router
              .push({
                name: 'filters-edit',
                params: { id: record.id },
              })
              .catch(_err => {})
          "
        />
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          title="Вы уверены что хотите удалить эту запись?"
          @confirm="deleteFilter(record)"
        >
          <a-button
            :style="{ marginLeft: '15px' }"
            type="danger"
            title="Удалить"
            icon="delete"
          />
        </a-popconfirm>
      </span>

      <div slot="expandedRowRender" slot-scope="filterRecord">
        <a-table
          size="small"
          :columns="valueColumns"
          :pagination="false"
          :row-key="record => record.id"
          :data-source="filterRecord.values"
        >
          <span
            slot="actions"
            slot-scope="record"
            :style="{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }"
          >
            <a-button
              icon="edit"
              title="Редактировать"
              type="primary"
              @click="
                $router
                  .push({
                    name: 'filter-values-edit',
                    params: { id: record.id },
                  })
                  .catch(_err => {})
              "
            />
          </span>
        </a-table>
      </div>
    </a-table>
  </div>
</template>
<script>
import columns from './table_columns'
import valueColumns from './value_table_columns'
import { debounce } from '@/utils'
import {
  FILTERS,
  GET_FILTERS,
  DELETE_FILTER,
  FILTERS_TOTAL,
  UPDATE_FILTER,
} from '@/store/types'

export default {
  data() {
    return {
      columns,
      valueColumns,
      loading: false,
      pageNumber: 1,
    }
  },

  computed: {
    filters() {
      return this.$store.getters[FILTERS]
    },
    filtersTotal() {
      return this.$store.getters[FILTERS_TOTAL]
    },
  },

  created() {
    this.getFilters()
  },

  methods: {
    getFilters: debounce(function() {
      const { pageNumber } = this

      this.$store.dispatch(GET_FILTERS, {
        pageNumber,
      })
    }, 300),

    handleTableChange(pagination) {
      this.$router.replace({
        name: 'filters-all',
        query: { ...this.$route.query, pageNumber: pagination.current },
      })

      this.pageNumber = pagination.current

      this.getFilters()
    },

    updateFilter(filter, field) {
      this.$store.dispatch(UPDATE_FILTER, {
        ...filter,
        [field]: !filter[field],
      })
    },

    deleteFilter(filter) {
      this.$store.dispatch(DELETE_FILTER, filter)
    },
  },
}
</script>
