export default [
  {
    title: 'ID',
    dataIndex: 'id',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
  {
    title: 'Значение',
    dataIndex: 'slug',
  },
  {
    title: 'Порядок',
    width: 100,
    dataIndex: 'sort_order',
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    fixed: 'right',
    width: 180,
    customRender: date => new Date(date).toLocaleString('ru-RU'),
  },
  {
    title: 'Изменено',
    dataIndex: 'updated_at',
    fixed: 'right',
    width: 180,
    customRender: date =>
      date ? new Date(date).toLocaleString('ru-RU') : 'Без изменений',
  },
  {
    title: 'Действия',
    key: 'actions',
    width: 150,
    fixed: 'right',
    scopedSlots: { customRender: 'actions' },
  },
]
