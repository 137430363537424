export default [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 100,
  },
  {
    title: 'Активный',
    width: 115,
    scopedSlots: { customRender: 'is_active' },
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
  {
    title: 'Значение',
    dataIndex: 'slug',
  },
  {
    title: 'Тип',
    dataIndex: 'type',
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    width: 180,
    customRender: date => new Date(date).toLocaleString('ru-RU'),
  },
  {
    title: 'Изменено',
    dataIndex: 'updated_at',
    width: 180,
    customRender: date =>
      date ? new Date(date).toLocaleString('ru-RU') : 'Без изменений',
  },
  {
    title: 'Действия',
    key: 'actions',
    width: 150,
    scopedSlots: { customRender: 'actions' },
  },
]
