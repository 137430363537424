var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-button',{style:({ marginBottom: '15px' }),attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push({ name: 'filters-new' }).catch(function (_err) {})}}},[_vm._v(" Создать новый фильтр ")]),_c('a-table',{attrs:{"bordered":"","columns":_vm.columns,"loading":_vm.loading,"row-key":function (record) { return record.id; },"pagination":{ current: _vm.pageNumber, total: _vm.filtersTotal },"data-source":_vm.filters},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"is_active",fn:function(record){return _c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('a-popconfirm',{attrs:{"ok-text":"Да","cancel-text":"Нет","placement":"topRight","title":("Вы уверены, что хотите " + (record.is_active ? 'отключить' : 'включить') + " значение?")},on:{"confirm":function($event){return _vm.updateFilter(record, 'is_active')}}},[_c('a-checkbox',{model:{value:(record.is_active),callback:function ($$v) {_vm.$set(record, "is_active", $$v)},expression:"record.is_active"}})],1)],1)}},{key:"actions",fn:function(record){return _c('span',{style:({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })},[_c('a-button',{attrs:{"icon":"edit","title":"Редактировать","type":"primary"},on:{"click":function($event){_vm.$router
            .push({
              name: 'filters-edit',
              params: { id: record.id },
            })
            .catch(function (_err) {})}}}),_c('a-popconfirm',{attrs:{"ok-text":"Да","cancel-text":"Нет","placement":"topRight","title":"Вы уверены что хотите удалить эту запись?"},on:{"confirm":function($event){return _vm.deleteFilter(record)}}},[_c('a-button',{style:({ marginLeft: '15px' }),attrs:{"type":"danger","title":"Удалить","icon":"delete"}})],1)],1)}},{key:"expandedRowRender",fn:function(filterRecord){return _c('div',{},[_c('a-table',{attrs:{"size":"small","columns":_vm.valueColumns,"pagination":false,"row-key":function (record) { return record.id; },"data-source":filterRecord.values},scopedSlots:_vm._u([{key:"actions",fn:function(record){return _c('span',{style:({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })},[_c('a-button',{attrs:{"icon":"edit","title":"Редактировать","type":"primary"},on:{"click":function($event){_vm.$router
                .push({
                  name: 'filter-values-edit',
                  params: { id: record.id },
                })
                .catch(function (_err) {})}}})],1)}}],null,true)})],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }